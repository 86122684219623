<!-- eslint-disable vue/attributes-order -->
<template>
  <div
    class="d-flex flex-wrap"
  >

    <!-- #region::Main card style. Verify if the conteny is currency or number type -->
    <b-card
      no-body
      class="w-card"
      :class="dashboardCardMainClass"
    >
      <div>

        <!-- #region::Main content class. Verify if type is currency or number to apply reverse style -->
        <b-row
          no-gutters
          :class="dashboardCardMainContentClass"
        >

          <!-- #region::Avatar -->
          <b-col
            v-if="!CURRENCY_CARDS.includes(card.condicion) && card.avatarStyle"
            class="d-flex justify-content-center align-items-center"
          >
            <b-avatar
              class="d-flex justify-content-center align-items-center rounded-circle icon-card"
              :class="card.avatarStyle"
            >
              <feather-icon
                :icon="card.icon"
                class="image-icon w-50 h-50"
              />
            </b-avatar>
          </b-col>
          <!-- #endregion::Avatar -->

          <!-- #region::Card description -->
          <b-col :class="!card.avatarStyle ? 'pl-2' : ''">
            <b-card-body
              class="d-flex flex-column pl-0"
            >

              <!-- #region::Title -->
              <strong class="mb-1">
                {{ card.condicion }}
              </strong>
              <!-- #endregion::Title -->

              <!-- #region::Content -->
              <b-card-text
                v-if="card.contentType === 'currency'"
                class="h1 mb-1"
                :class="card.avatarStyle ? 'format_number' : ''"
              >
                {{ card.data | currency }}
              </b-card-text>

              <b-card-text
                v-else
                class="h1 mb-1"
              >
                {{ formatNumber(card.data) }}
              </b-card-text>
              <!-- #endregion::Content -->

              <!-- #region::Redirect link -->
              <div
                v-if="!CURRENCY_CARDS.includes(card.condicion)"
                class="card-link"
                @click="redirect(card.link, card.params)"
              >
                Ver detalles
              </div>
              <!-- #endregion::Redirect link -->

            </b-card-body>
          </b-col>
          <!-- #endregion::Card description -->

          <!-- #region::Filter -->
          <b-col
            v-if="type === 'Ventas' && card.condicion != 'Productos'"
            class="d-flex align-items-center"
            :class="CURRENCY_CARDS.includes(card.condicion) ? `justify-content-start` : `justify-content-center ${type}`"
          >
            <div class="filter">
              <DashboardFilters
                :filter-type="filterType"
              />
            </div>
          </b-col>
          <!-- #endregion::Filter -->

        </b-row>
        <!-- #endregion::Main content class. Verify if type is currency or number to apply reverse style -->

      </div>
    </b-card>
    <!-- #endregion::Main card style. Verify if the conteng is currency or number type -->

  </div>

</template>

<script>
import { mapActions } from 'vuex'
import {
  BCard, BCardText, BCardBody, BRow, BCol, BAvatar,
} from 'bootstrap-vue'

import DashboardFilters from '@/modules/home/dashboard/components/DashboardFilters.vue'

const CURRENCY_CARDS = ['Total de venta', 'Pagos registrados']

export default {
  name: 'DashsoardCard',
  components: {
    BCard,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BAvatar,
    DashboardFilters,
  },
  props: {
    card: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
    role: {
      type: String,
      default: '',
    },
    rangeDefault: {
      type: String,
      default: '',
    },
    filterType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      orderStatus: null,
      paymentStatus: null,
      CURRENCY_CARDS,
    }
  },
  computed: {
    dashboardCardMainClass() {
      return this.CURRENCY_CARDS.includes(this.card.condicion) ? 'card-ventas' : ''
    },
    dashboardCardMainContentClass() {
      return this.CURRENCY_CARDS.includes(this.card.condicion) ? 'reverse' : ''
    },
  },
  created() {
    // console.log(this.card);
  },
  methods: {
    ...mapActions({
      listenerParmasDashboard: 'dashboard/listenerParmasDashboard',
    }),
    redirect(link, params) {
      this.listenerParmasDashboard(params)
      setTimeout(() => {
        this.$router.push({ name: link })
      }, 150)
    },

    async handleFilter(filter) {
      this.$emit('on:filter', filter)
    },
    formatNumber(value) {
      const formatter = new Intl.NumberFormat('en-US', {
        currency: 'USD',
        maximumFractionDigits: 0,
        minimumFractionDigits: 0,
      })
      return formatter.format(value)
    },
  },
}
</script>

<style lang="scss" scoped>

.format_number{
  color: #0CA852;
}
.reverse{
  flex-direction: column-reverse;
  padding: 16px;
}

.Ventas{
  align-items: flex-start!important;
  .filter{
    padding: 20px 20px 0 0;
  }
}
.w-card {
  width: 320px;
  margin: 10px;
  border: 1px solid #e9ecef;
  box-shadow: none!important;
  max-height: 150px;
}
.card-ventas{
  max-height: inherit;
  padding-bottom: 4px;
  .card-body{
    padding-bottom: 0;
    p{
      margin-bottom: 0!important;
    }
  }
}
.icon-card {
  width: 90px;
  height: 90px;
}

.card-link{
  color: #0367b4;
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}
</style>>
