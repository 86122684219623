<!-- eslint-disable no-return-assign -->
<template>
  <div>
    <DashboardGropuCard
      :cards-dashboard="cardsDashboard"
      :role="role"
      tipo="Ventas"
    />
    <DashboardGropuCard
      :cards-dashboard="cardsDashboard"
      :role="role"
      tipo="Usuarios"
    />
    <DashboardGropuCard
      :cards-dashboard="cardsDashboard"
      :role="role"
      tipo="Cotizaciones"
    />
    <DashboardGropuCard
      :cards-dashboard="cardsDashboard"
      :role="role"
      tipo="Órdenes"
    />
    <DashboardGropuCard
      :cards-dashboard="cardsDashboard"
      :role="role"
      tipo="Traspasos"
    />
    <DashboardGropuCard
      :cards-dashboard="cardsDashboard"
      :role="role"
      tipo="Devoluciones"
    />

  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

import DashboardGropuCard from '@/modules/home/dashboard/components/DashboardGropuCard.vue'

// Roles de usuarios que se aplican al color cyan en cards
const CYAN_COLOR_USERS_ROLES = ['Mayorista', 'Vendedor', 'Administrador']

export default {
  components: {
    DashboardGropuCard,
  },
  data() {
    return {
      isLoading: false,
      rangeDefault: '',
      cardsDashboard: [],
      role: '',
      titleCards: [
        {
          title: 'Productos',
          key: 'products',
          icon: 'ArchiveIcon',
          link: 'products-home',
          params: '',
          tipo: 'Ventas',
          contentType: 'number',
          avatarStyle: ['gray-avatar'],
        },
        {
          title: 'Total de venta',
          key: 'total',
          icon: '',
          link: 'dashboard',
          params: '',
          tipo: 'Ventas',
          filterType: 'totalSale',
          contentType: 'currency',
          avatarStyle: ['gray-avatar'],
        },
        {
          title: 'Pagos registrados',
          key: 'total',
          icon: '',
          link: 'dashboard',
          params: '',
          tipo: 'Ventas',
          contentType: 'currency',
          avatarStyle: ['gray-avatar'],
        },
        {
          title: 'Vendedores',
          key: 'sellers',
          icon: 'DollarSignIcon',
          link: 'sellers-home',
          params: '',
          tipo: 'Usuarios',
          contentType: 'number',
          avatarStyle: ['green-avatar'],
        },
        {
          title: 'Mayoristas',
          key: 'wholesalers',
          icon: 'ArchiveIcon',
          link: 'wholesalers-home',
          params: '',
          tipo: 'Usuarios',
          contentType: 'number',
          avatarStyle: ['orange-avatar'],
        },
        {
          title: 'Clientes',
          key: 'clients',
          icon: 'UsersIcon',
          link: 'customers-home',
          params: '',
          tipo: 'Usuarios',
          contentType: 'number',
          avatarStyle: ['blue-avatar'],
        },
        {
          title: 'Creadas',
          key: 'quotesCreated',
          icon: 'FileTextIcon',
          link: 'quotes-home',
          params: '',
          tipo: 'Cotizaciones',
          contentType: 'number',
          avatarStyle: ['blue-avatar'],
        },
        {
          title: 'En solicitud',
          key: 'quotesOnRequest',
          icon: 'ClockIcon',
          link: 'quotes-home',
          params: 'Solicitud',
          tipo: 'Cotizaciones',
          contentType: 'number',
          avatarStyle: ['orange-avatar'],
        },
        {
          title: 'Canceladas',
          key: 'quotesStatusCancel',
          icon: 'XIcon',
          link: 'quotes-home',
          params: 'Cancelado',
          tipo: 'Cotizaciones',
          contentType: 'number',
          avatarStyle: ['red-avatar'],
        },
        {
          title: 'Creadas',
          key: 'ordersCreated',
          icon: 'FileTextIcon',
          link: 'orders-home',
          params: '',
          tipo: 'Órdenes',
          contentType: 'number',
          avatarStyle: ['green-avatar'],
        },
        {
          title: 'Listas para entrega',
          key: 'readyDeliveryOrders',
          icon: 'TruckIcon',
          link: 'orders-home',
          params: 'Lista para entrega',
          tipo: 'Órdenes',
          contentType: 'number',
          avatarStyle: ['blue-avatar'],
        },
        {
          title: 'Pagos completos',
          key: 'fullPaymentOrders',
          icon: 'DollarSignIcon',
          link: 'orders-home',
          params: 'Pago liquidado',
          tipo: 'Órdenes',
          contentType: 'number',
          avatarStyle: ['green-avatar'],
        },
        {
          title: 'Pendientes de pago',
          key: 'pendingPaymentOrders',
          icon: 'ClockIcon',
          link: 'orders-home',
          params: 'Pago pendiente',
          tipo: 'Órdenes',
          contentType: 'number',
          avatarStyle: ['orange-avatar'],
        },
        {
          title: 'Pagos completos',
          key: 'totalPayPaymentOrders',
          icon: 'DollarSignIcon',
          link: 'orders-home',
          params: 'Pago liquidado',
          tipo: 'Órdenes',
          contentType: 'currency',
          avatarStyle: ['green-avatar'],
        },
        {
          title: 'Pendientes de pago',
          key: 'totalPendingPaymentOrders',
          icon: 'ClockIcon',
          link: 'orders-home',
          params: 'Pago pendiente',
          tipo: 'Órdenes',
          contentType: 'currency',
          avatarStyle: ['orange-avatar'],
        },
        {
          title: 'Aprobadas',
          key: 'approveOrders',
          icon: 'CheckIcon',
          link: 'orders-home',
          params: 'Aprobada',
          tipo: 'Órdenes',
          contentType: 'number',
          avatarStyle: ['blue-avatar'],
        },
        {
          title: 'Completadas',
          key: 'completeOrders',
          icon: CYAN_COLOR_USERS_ROLES.includes(JSON.parse(localStorage?.userData).Role)
            ? 'ArchiveIcon'
            : 'BookOpenIcon',
          link: 'orders-home',
          params: 'Completada',
          tipo: 'Órdenes',
          contentType: 'number',
          avatarStyle: CYAN_COLOR_USERS_ROLES.includes(JSON.parse(localStorage?.userData).Role)
            ? ['cyan-avatar']
            : ['green-avatar'],
        },
        {
          title: 'Canceladas',
          key: 'cancelOrders',
          icon: 'SlashIcon',
          link: 'orders-home',
          params: 'Cancelada',
          tipo: 'Órdenes',
          contentType: 'number',
          avatarStyle: ['dark-avatar'],
        },
        {
          title: 'En bodega',
          key: 'storeOrders',
          icon: 'TrelloIcon',
          link: 'orders-home',
          params: 'En bodega',
          tipo: 'Órdenes',
          contentType: 'number',
          avatarStyle: ['purple-avatar'],
        },
        {
          title: 'Detenidas',
          key: 'arrestedOrders',
          icon: 'XIcon',
          link: 'orders-home',
          params: 'Detenida',
          tipo: 'Órdenes',
          contentType: 'number',
          avatarStyle: ['red-avatar'],
        },
        {
          title: 'Entrega Parcial',
          key: 'partialDeliveryOrders',
          icon: 'LayersIcon',
          link: 'orders-home',
          params: 'Entrega parcial',
          tipo: 'Órdenes',
          contentType: 'number',
          avatarStyle: ['cyan-avatar'],
        },
        {
          title: 'En suministro',
          key: 'inSubmissionOrders',
          icon: 'ClockIcon',
          link: 'orders-home',
          params: 'En suministro',
          tipo: 'Órdenes',
          contentType: 'number',
          avatarStyle: ['red-avatar'],
        },
        {
          title: 'Entrega Completa',
          key: 'completeDeliveryOrders',
          icon: 'ArchiveIcon',
          link: 'orders-home',
          params: 'Entrega completa',
          tipo: 'Órdenes',
          contentType: 'number',
          avatarStyle: ['blue-avatar'],
        },
        {
          title: 'Solicitud de factura',
          key: 'invoiceRequired',
          icon: 'FileTextIcon',
          link: 'orders-home',
          params: '',
          tipo: 'Órdenes',
          contentType: 'number',
          avatarStyle: ['purple-avatar'],
        },
        /**
         * Contenido de traspasos
         */
        {
          title: 'Totales',
          key: 'totalTransfers',
          icon: 'RefreshCwIcon',
          link: 'transfers-list',
          params: '',
          tipo: 'Traspasos',
          contentType: 'number',
          avatarStyle: ['blue-avatar'],
        },
        {
          title: 'Traspaso',
          key: 'transfers',
          icon: 'RefreshCwIcon',
          link: 'transfers-list',
          params: 'Traspaso',
          tipo: 'Traspasos',
          contentType: 'number',
          avatarStyle: ['cyan-avatar'],
        },
        {
          title: 'En solicitud',
          key: 'requestTransfers',
          icon: 'RefreshCwIcon',
          link: 'transfers-list',
          params: 'Solicitud',
          tipo: 'Traspasos',
          contentType: 'number',
          avatarStyle: ['purple-avatar'],
        },
        {
          title: 'Aprobados',
          key: 'approvedTransfers',
          icon: 'RefreshCwIcon',
          link: 'transfers-list',
          params: 'Aprobada',
          tipo: 'Traspasos',
          contentType: 'number',
          avatarStyle: ['green-avatar'],
        },
        {
          title: 'Rechazados',
          key: 'rejectedTransfers',
          icon: 'RefreshCwIcon',
          link: 'transfers-list',
          params: 'Rechazada',
          tipo: 'Traspasos',
          contentType: 'number',
          avatarStyle: ['red-avatar'],
        },
        {
          title: 'Listos para traspaso',
          key: 'readyForTransferTransfers',
          icon: 'RefreshCwIcon',
          link: 'transfers-list',
          params: 'Listo para traspaso',
          tipo: 'Traspasos',
          contentType: 'number',
          avatarStyle: ['dark-avatar'],
        },
        {
          title: 'En tránsito',
          key: 'inTransitTransfers',
          icon: 'RefreshCwIcon',
          link: 'transfers-list',
          params: 'En transito',
          tipo: 'Traspasos',
          contentType: 'number',
          avatarStyle: ['orange-avatar'],
        },
        {
          title: 'Recibidos',
          key: 'receivedTransfers',
          icon: 'RefreshCwIcon',
          link: 'transfers-list',
          params: 'Recibida',
          tipo: 'Traspasos',
          contentType: 'number',
          avatarStyle: ['blue-avatar'],
        },
        {
          title: 'Cancelados',
          key: 'cancelledTransfers',
          icon: 'RefreshCwIcon',
          link: 'transfers-list',
          params: 'Cancelado',
          tipo: 'Traspasos',
          contentType: 'number',
          avatarStyle: ['gray-avatar'],
        },
        /**
         * Contenido de devoluciones
         */
        {
          title: 'Totales',
          key: 'totalDevolutions',
          icon: 'ExternalLinkIcon',
          link: 'refunds-list',
          params: '',
          tipo: 'Devoluciones',
          contentType: 'number',
          avatarStyle: ['blue-avatar'],
        },
        {
          title: 'En solicitud',
          key: 'requestDevolutions',
          icon: 'ExternalLinkIcon',
          link: 'refunds-list',
          params: 'Solicitud',
          tipo: 'Devoluciones',
          contentType: 'number',
          avatarStyle: ['purple-avatar'],
        },
        {
          title: 'Aprobadas',
          key: 'approvedDevolutions',
          icon: 'ExternalLinkIcon',
          link: 'refunds-list',
          params: 'Aprobada',
          tipo: 'Devoluciones',
          contentType: 'number',
          avatarStyle: ['green-avatar'],
        },
        {
          title: 'Rechazadas',
          key: 'rejectedDevolutions',
          icon: 'ExternalLinkIcon',
          link: 'refunds-list',
          params: 'Rechazada',
          tipo: 'Devoluciones',
          contentType: 'number',
          avatarStyle: ['red-avatar'],
        },
        {
          title: 'Recibidas',
          key: 'receivedDevolutions',
          icon: 'ExternalLinkIcon',
          link: 'refunds-list',
          params: 'Recibida',
          tipo: 'Devoluciones',
          contentType: 'number',
          avatarStyle: ['blue-avatar'],
        },
        {
          title: 'Canceladas',
          key: 'cancelledDevolutions',
          icon: 'ExternalLinkIcon',
          link: 'refunds-list',
          params: 'Cancelada',
          tipo: 'Devoluciones',
          contentType: 'number',
          avatarStyle: ['gray-avatar'],
        },
        {
          title: 'Total de devoluciones',
          key: 'totalMxnDevolutions',
          icon: '',
          link: 'refunds-list',
          params: '',
          tipo: 'Devoluciones',
          contentType: 'currency',
          avatarStyle: '',
        },
        /**
         * Sección de pagos registrados con filtro de rango
         * de fechas
         */
        {
          title: 'Pagos registrados',
          key: 'registeredPayments',
          icon: '',
          link: 'dashboard',
          params: '',
          tipo: 'Ventas',
          contentType: 'currency',
          filterType: 'registeredPayments',
          avatarStyle: ['gray-avatar'],
        },
      ],
    }
  },
  computed: {
    ...mapGetters({
      getTotalSaleRangeDates: 'dashboard/getTotalSaleRangeDates',
      getRegisteredPaymentsRangeDates: 'dashboard/getRegisteredPaymentsRangeDates',
    }),
    /**
     * Rango de fechas del total de venta
     */
    totalSaleRangeDates: {
      get() { return this.getTotalSaleRangeDates },
      set(value) { this.setTotalSaleRangeDates(value) },
    },
    /**
     * Rango de fechas de los pagos registrados
     */
    registeredPaymentsRangeDates: {
      get() { return this.getRegisteredPaymentsRangeDates },
      set(value) { this.setRegisteredPaymentsRangeDates(value) },
    },
  },
  watch: {
    totalSaleRangeDates(newTotalSaleRangeDates) {
      this.loadTotalSale(newTotalSaleRangeDates)
    },
    registeredPaymentsRangeDates(newRegisteredPaymentsRangeDates) {
      this.loadTotalRegisteredPayments(newRegisteredPaymentsRangeDates)
    },
  },
  async created() {
    this.getUseRole()
    await this.loadDashboard()
  },
  methods: {
    ...mapActions({
      getDashboard: 'dashboard/getDashboard',
      getDateDashboard: 'dashboard/gateDateDashboard',
      loadRegisteredPayments: 'dashboard/loadRegisteredPayments',
    }),

    /**
    * Carga los datos del dashboard
    * @summary Obtiene los datos del dashboard desde la API, se asignan a la variable dataResponse
    * @return {ReturnValueDataTypeHere} Brief description of the returning value here.
    */
    async loadDashboard() {
      // Show a loading screen
      this.$swal({
        title: 'Cargando...',
        allowOutsideClick: false,
      })
      this.$swal.showLoading()
      this.isLoading = true

      // Get the dashboard data from the API
      const response = await this.getDashboard()

      if (this.$ability.can('read_registered_payments', 'Dashboard')) {
        const registeredPaymentsResponse = await this.loadRegisteredPayments()
        response.data.registeredPayments = registeredPaymentsResponse.data.data.total_payment_recorded
      }

      const dataResponse = []
      Object.keys(response.data).forEach(item => {
        dataResponse.push({
          data: response.data[item],
          icon: this.titleCards.filter(card => card.key === item)[0]?.icon,
          tipo: this.titleCards.filter(card => card.key === item)[0]?.tipo,
          link: this.titleCards.filter(card => card.key === item)[0]?.link,
          params: this.titleCards.filter(card => card.key === item)[0]?.params,
          condicion: this.titleCards.filter(card => card.key === item)[0]?.title,
          filterType: this.titleCards.filter(card => card.key === item)[0]?.filterType,
          contentType: this.titleCards.filter(card => card.key === item)[0]?.contentType,
          avatarStyle: this.titleCards.filter(card => card.key === item)[0]?.avatarStyle,
          key: item,
        })
      })

      this.cardsDashboard = dataResponse

      // Hide the loading screen
      this.isLoading = false
      this.$swal.close()
    },

    /**
    * Total de venta
    * @summary Atiende el evento de la carga del total de venta
    * @param {Object} filter - Filtro de rango de fechas
    */
    async loadTotalSale(filter) {
      let response = null

      if (filter) {
        response = await this.getDateDashboard(filter)
      } else {
        response = await this.getDashboard()
      }
      this.cardsDashboard.map(card => {
        if (String(card.key) === 'total') {
          // eslint-disable-next-line no-param-reassign
          card.data = response.data.total
        }
        return card
      })
    },

    /**
    * Pagos registrados
    * @summary Atiende el evento de la carga de los pagos registrados
    * @param {Object} filter - Filtro de rango de fechas
    */
    async loadTotalRegisteredPayments(filter) {
      let response = null

      if (filter) {
        response = await this.loadRegisteredPayments(filter)
      } else {
        response = await this.loadRegisteredPayments()
      }
      this.cardsDashboard.map(card => {
        if (String(card.key) === 'registeredPayments') {
          // eslint-disable-next-line no-param-reassign
          card.data = response.data.data.total_payment_recorded
        }
        return card
      })
    },

    /**
    * Información del usuario
    * @summary Obtiene la información del usuario desde el localstorage
    */
    getUseRole() {
      const user = JSON.parse(localStorage?.userData)
      this.role = user?.Role
    },
  },
}
</script>
