<template>
  <div>
    <h3
      v-if="cardsDashboard.some(card => card.tipo === tipo)"
      class="w-100 my-2"
    >
      {{ tipo }}
    </h3>
    <b-row>
      <template>
        <div
          v-for="cards in cardsDashboard"
          :key="cards.id"
          class="d-flex flex-wrap"
        >
          <DashsoardCard
            v-if="cards.tipo === tipo"
            :card="cards"
            :type="cards.tipo"
            :role="role"
            :filter-type="cards.filterType"
          />
        </div>
      </template>
    </b-row>

  </div>
</template>

<script>
import {
  BRow,
} from 'bootstrap-vue'

import DashsoardCard from '@/modules/home/dashboard/components/DashsoardCard.vue'

export default {
  components: {
    BRow, DashsoardCard,
  },
  props: {
    cardsDashboard: {
      type: Array,
      default: () => [],
    },
    role: {
      type: String,
      default: '',
    },
    tipo: {
      type: String,
      default: '',
    },
    rangeDefault: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },

  watch: {},

  methods: {
    handleFilter(filter) {
      this.$emit('on:filter', filter)
    },
  },
}
</script>
